.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 2;
}
.popup {
  margin: 25% auto;
  padding: 1%;
  background: #fff;
  border-radius: 15px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 3;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 1%;
  right: 5%;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  cursor: pointer;
  color: #000;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
  z-index: 4;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 90%;
  }
}
